.style1 {
    font-family: sans-serif;
    text-align: center;
    width: 90vw;
    margin: 5rem;
    display: grid;
    gap:2rem;

}

h1 {
    text-align: center;
    margin-top: 5rem;

}